import {
  Button,
  Card,
  CircularProgress,
  experimentalStyled,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import {
  contractAcceptDoc,
  contractRejectDoc,
  contractRemoveDoc,
  saveContractDoc,
} from '../../redux/thunks/docs.thunk';
import docService from '../../services/docService';
import QRCode from 'react-qr-code';
import FacturaItem from '../../components/FacturaItem';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { set } from 'lodash';

const StyledCard = experimentalStyled(Card)(({ theme }) => ({
  display: 'inline-block',
  padding: 15,
  marginBottom: 20,
  width: '100%',
}));

const ContractPreview = () => {
  const params = useParams();
  const dispatch = useDispatch();
  // @ts-ignore
  const userTin = useSelector(state => state.auth.userTin);

  const [docData, setDocData] = useState<any>(null);
  const [laoder, setLoader] = useState(true);
  const [type, setType] = useState(null);
  const [signedFile, setSignedFile] = useState(null);
  const location = useLocation();
  const [operatorCode, setOperatorCode] = useState('');
  const printRef = useRef<HTMLDivElement>(null);
  const [unitList, setUnitList] = useState([]);

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    if (!element) return;

    const pdf = new jsPDF('p', 'mm', 'a4');
    const canvas = await html2canvas(element, { scale: 2 }); // Yaxshiroq sifat uchun scale 2
    const imgData = canvas.toDataURL('image/png');

    const imgWidth = 210; // A4 kengligi mm
    const pageHeight = 297; // A4 balandligi mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft > 0) {
      position -= pageHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    pdf.save('contract.pdf');
  };

  const fetchDocData = () => {
    docService
      .getContractData(params.id, userTin)
      // @ts-ignore
      .then((res: any) => {
        // @ts-ignore
        setDocData(res.data);
        // @ts-ignore
        setSignedFile(res.seller_sign);
        // @ts-ignore
        if (userTin === res.data.owner.tin) setType('sender');
        else if (
          // @ts-ignore
          res.data.clients?.some(client => client.tin === userTin)
        )
          // @ts-ignore
          setType('receiver');
      })
      .finally(() => setLoader(false));
  };
  const fetchOperatorCode = () => {
    docService.getOperatorCode().then(operator => {
      // @ts-ignore
      setOperatorCode(operator);
    });
  };

  const fetchUnitList = () => {
    docService.getUnitList().then(unit => {
      // @ts-ignore
      setUnitList(unit);
    });
  };

  const acceptHandler = () => {
    // @ts-ignore
    dispatch(contractAcceptDoc(signedFile, params.id));
  };

  const rejectHandler = () => {
    // @ts-ignore
    dispatch(contractRejectDoc(docData, params.id, 'Sababi nomalum'));
  };

  const removeHandler = () => {
    dispatch(
      // @ts-ignore
      contractRemoveDoc(
        { ContractId: params.id, OwnerTin: userTin },
        'contract',
        params.id,
      ),
    );
  };

  const copyHanlder = () => {
    // @ts-ignore
    dispatch(saveContractDoc(docData, docData.products));
  };

  useEffect(() => {
    fetchDocData();
    fetchOperatorCode();
    fetchUnitList();
  }, []);

  if (laoder)
    return (
      <div
        style={{
          width: '100%',
          minHeight: '500px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <CircularProgress />
      </div>
    );
  const qrCodeValue = `https://my3.soliq.uz/roaming-viewer/ru/document?id=${params.id}&filetype=7&tin=${location?.state}`;
  return (
    <div>
      {/* @ts-ignore */}
      <Header
        // @ts-ignore
        title={`Договор № ${docData?.contractDoc.contractNo || '---'} от ${
          // @ts-ignore
          docData?.contractDoc.contractDate || '---'
        }`}></Header>

      <div style={{ padding: '20px' }}>
        <StyledCard elevation={12}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            {/* <TableTag color={"primary"}>{docStatus}</TableTag> */}
            <div style={{ display: 'flex', gridGap: '10px' }}>
              {/* @ts-ignore */}
              <Button
                variant="contained"
                color="warning"
                onClick={handleDownloadPdf}>
                Скачать PDF
              </Button>
              <Button variant="contained" color="primary" onClick={copyHanlder}>
                Дублировать
              </Button>
              {/* <Button
                variant="contained"
                color="warning"
                onClick={() =>
                  navigate(`/main/contract/create`, {
                    // @ts-ignore
                    state: docData,
                  })
                }>
                Редактировать
              </Button> */}
              {/* @ts-ignore */}
              {type === 'receiver' && docData?.CurrentStateID === 15 && (
                <>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={rejectHandler}>
                    Отказать
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={acceptHandler}>
                    Принять
                  </Button>
                </>
              )}
              {/* @ts-ignore */}
              {type === 'sender' && docData?.CurrentStateID === 15 && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={removeHandler}>
                  Отменить
                </Button>
              )}
            </div>
          </div>
        </StyledCard>
        <div
          style={{
            height: '100%',
            width: '100%',
          }}
          ref={printRef}>
          <div className="containerContract" style={{ paddingTop: '0px' }}>
            <div
              style={{
                width: '100%',
                height: '150px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}>
              <QRCode
                style={{
                  height: '100px',
                  maxWidth: '100px',
                  width: '100px',
                }}
                value={qrCodeValue}
                viewBox={`0 0 256 256`}
              />
            </div>
            <div className="title">
              {/* @ts-ignore */}
              <p>{docData.contractDoc.contractName}</p>
              {/* @ts-ignore */}
              <p>Договор № {docData.contractDoc.contractNo}</p>
            </div>

            <div className="subtitle">
              <div className="left">
                {/* @ts-ignore */}
                <p>{docData.contractDoc.contractPlace}</p>
                <p>(место заключения договора)</p>
              </div>
              <div className="right">
                {/* @ts-ignore */}
                <p>{docData.contractDoc.contractDate}</p>
                <p>(дата заключения договора)</p>
              </div>
            </div>

            <div className="info-block">
              {docData.ownerName} (именуемое в дальнейшем – Исполнитель) в лице
              директора {docData.owner.fio}, с одной стороны, и (именуемое в
              дальнейшем – Заказчик) в лице директора , с другой стороны, вместе
              именуемые Стороны, а по отдельности - Сторона, заключили настоящий
              договор о следующем:
            </div>

            <strong>
              По настоящему договору Заказчик оплачивает и принимает, а
              Исполнитель поставляет товар(услуг) на следующих условиях:
            </strong>
            {/* @ts-ignore */}
            {docData.products.map(product => (
              <table cellSpacing="0" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>№</th>
                    <th>
                      Идентификационный код и название по Единому электронному
                      национальному каталогу товаров (услуг)
                    </th>
                    <th>Штрих-код товара/услуги</th>
                    <th>Единица измерения</th>
                    <th>Количество</th>
                    <th>Цена</th>
                    <th>Стоимость поставки</th>
                    <th>НДС</th>
                    <th>Стоимость поставки с учетом НДС</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>{product.ordNo}</td>
                    <td>
                      {product.catalogCode} "--" {product.catalogName}
                    </td>
                    <td>{product.barCode}</td>
                    <td>
                      {
                        unitList.find(
                          (unit: any) => unit.id === product.measureId,
                          // @ts-ignore
                        )?.name
                      }
                    </td>
                    <td>{product.count}</td>
                    <td>{product.summa}</td>
                    <td>{product.deliverySum}</td>
                    <td>{product.vatRate}</td>
                    <td>{product.deliverySumWithVat}</td>
                  </tr>
                  <tr>
                    <td className="bold" colSpan={8}>
                      Итого
                    </td>
                    <td className="center bold">
                      {product.deliverySumWithVat}
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
            {/* @ts-ignore */}
            {docData.products.map(product => (
              <p className="bold">
                Общая сумма договора составляет {product.deliverySumWithVat}
                (сумма прописью)
              </p>
            ))}
            {/* @ts-ignore */}
            {docData.parts.map(item => (
              <>
                <div className="part-title">{item.title}</div>
                <div className="part-body">
                  {item.body
                    .replace(/(\d+\.\d+\.|\d+\.)/g, '\n$1')
                    .replace(/;/g, ';\n')
                    .split('\n')
                    // @ts-ignore
                    .map((line, index) => (
                      <p key={index}>{line}</p>
                    ))}
                </div>
              </>
            ))}

            <div className="title">
              <p>Юридические адреса и реквизиты сторон</p>
            </div>

            <div className="humans-wrapper">
              <div className="humans">
                <p>
                  <b>Исполнитель: </b>
                </p>
                <p>
                  {/* @ts-ignore */}
                  <b>Наименование: {docData.owner.name} </b>
                </p>
                <p>
                  {/* @ts-ignore */}
                  <b>Адрес: {docData.owner.address} </b>
                </p>
                <p>
                  {/* @ts-ignore */}
                  <b>Тел: {docData.owner.workPhone} </b>
                </p>
                <p>
                  {/* @ts-ignore */}
                  <b>Факс: {docData.owner.fax} </b>
                </p>
                <p>
                  {/* @ts-ignore */}
                  <b>ИНН: {docData.owner.tin} </b>
                </p>
                <p>
                  {/* @ts-ignore */}
                  <b>ОКЭД: {docData.owner.oked}</b>
                </p>
                <p>
                  {/* @ts-ignore */}
                  <b>Р/С: {docData.owner.account}</b>
                </p>
                <p>
                  <b>Банк: </b>
                </p>
                <p>
                  {/* @ts-ignore */}
                  <b>МФО: {docData.owner.bankId} </b>
                </p>
              </div>
              <div className="humans">
                <p>
                  <b>Заказчик: </b>
                </p>
                {/* @ts-ignore */}
                {docData.clients.map(client => (
                  <>
                    <p>
                      <b>Наименование: {client.name}</b>
                    </p>
                    <p>
                      <b>Адрес: {client.address}</b>
                    </p>
                    <p>
                      <b>Тел: {client.mobile}</b>
                    </p>
                    <p>
                      <b>Факс: {client.fax}</b>
                    </p>
                    <p>
                      <b>ИНН: {client.tin}</b>
                    </p>
                    <p>
                      <b>ОКЭД: {client.oked}</b>
                    </p>
                    <p>
                      <b>Р/С: {client.account}</b>
                    </p>
                    <p>
                      <b>Банк: </b>
                    </p>
                    <p>
                      <b>МФО: {client.bankId}</b>
                    </p>
                  </>
                ))}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '20px',
                  }}>
                  {docData?.states
                    .filter((item: any) => item.stateId !== 15)
                    .map((item: any) => {
                      return (
                        <FacturaItem
                          type={
                            item.stateId === 10
                              ? 'nosucses'
                              : item.stateId === 17
                              ? 'canceled'
                              : item.stateId === 20
                              ? 'canceled'
                              : 'sucses'
                          }
                          title={
                            item.stateId === 10
                              ? 'Yuborilgan'
                              : item.stateId === 17
                              ? 'Bekor qildi'
                              : item.stateId === 20
                              ? 'Qabul qilmadi'
                              : 'Tasdiqlandi'
                          }
                          identifikatsiya={docData?.sellerTin}
                          operator={
                            // @ts-ignore
                            operatorCode[item.sender.dataSource]?.FirstStateName
                          }
                          id={docData?.actId}
                          date={moment(item?.timestamp).format(
                            'YYYY.MM.DD HH:mm:ss',
                          )}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractPreview;
