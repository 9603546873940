import axios, { AxiosError } from 'axios';
import { store } from '../redux/store';
import { authActions } from '../redux/slices/auth.slice';
import { showAlert } from '../redux/thunks/alert.thunk';

// Base URL without the /api path
export const baseURL = 'https://kiber.uz';

const directRequest = axios.create({
    baseURL,
    timeout: 100000,
});

const errorHandler = (error: AxiosError, hooks?: any) => {
    if (error?.response) {
        const errorData = error.response.data as any;
        if (errorData?.errorMessage) {
            store.dispatch(
                showAlert(errorData.errorMessage, 'error', 6000),
            );
        }

        if (error?.response?.status === 401) {
            store.dispatch(authActions.logout({}));
        }
    } else {
        store.dispatch(showAlert('_______Error________', 'error'));
    }

    return Promise.reject(error.response);
};

// Store the client IP once we retrieve it
let clientIP = '';

// Try to get the client IP from an IP service
try {
    fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
            clientIP = data.ip;
        })
        .catch(err => {
            console.error('Could not retrieve client IP:', err);
        });
} catch (error) {
    console.error('Error fetching client IP:', error);
}

directRequest.interceptors.request.use(
    config => {
        const storageToken = localStorage.getItem('token');

        if (typeof storageToken !== 'undefined' && storageToken !== null) {
            if (config.headers) {
                config.headers.Authorization = `Bearer ${JSON.parse(storageToken)}`;

                // Always remove problematic headers
                delete config.headers['Host'];

                // For any e-imzo related calls like pkcs7/join
                if (config.url?.includes('pkcs7')) {
                    // Don't transform data for PKCS7 operations - it needs raw data
                    config.transformRequest = [(data) => data];
                }
            }
        }

        // Add x-real-ip and host headers
        if (config.headers) {
            // Use the client IP if available
            if (clientIP) {
                config.headers['x-real-ip'] = clientIP;
            }
            config.headers['host'] = 'kiber.uz';
        }

        return config;
    },
    error => errorHandler(error),
);

directRequest.interceptors.response.use(
    response => response.data,
    errorHandler,
);

export default directRequest; 