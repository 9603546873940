import reqGenerator from '../../utils/reqGenerator';
import { measuresActions } from '../slices/measures.slice';

//@ts-ignore
export const fetchMeasureList = () => dispatch => {
  reqGenerator.get('/catalog/measures').then(res => {
    if (!res) return null;
    dispatch(
      measuresActions.setMeasureList(
        //@ts-ignore
        res.map(el => ({ value: el.measureId, label: el.name })),
      ),
    );
  });
};
//@ts-ignore
export const fetchCatalogList = () => (dispatch, getState) => {
  const key = getState().auth.userTin;
  const key1 = getState().auth.key;

  console.log({ key, key1 });

  reqGenerator
    .get('/product/list', { params: { tin: key ? key : key1.tin } })
    .then(res => {
      if (!res) return null;
      dispatch(measuresActions.setCatalogList(res));
    });
};
