import computeActJSON from '../../utils/computeActJSON';
import reqGenerator from '../../utils/reqGenerator';
import directRequest from '../../utils/directRequest';
import { showAlert } from './alert.thunk';
import history from '../../history';
import { loaderAction } from '../slices/loader.slice';
import computeEmpowermentJSON from '../../utils/computeEmpowermentJSON';
import computeFacturaJSON from '../../utils/computeFacturaJSON';
import putSignature from '../../utils/putSignature';
import putAttachedSignature from '../../utils/putAttachedSignature';
import draftService from '../../services/draftService';
import computeContractJSON from '../../utils/computeContractJSON';
import computeWaybill from '../../utils/computeWaybill';
import computeVerificationActJSON from '../../utils/computeVerificationAct';
import computePlaneDocJson from '../../utils/computePlaneDocJson';

const { loaderOFF, loaderON } = loaderAction;

export const createWaybillDoc =
  //@ts-ignore
  (values, productList, tin) => async (dispatch, getState) => {
    const guid = await reqGenerator.get('/utils/guid');
    const catalogList = getState().measures.catalogList;
    const key = getState().auth.key;

    let data = null;

    try {
      data = JSON.stringify(
        computeWaybill(values, productList, guid, tin, catalogList),
      );
    } catch (error) {
      console.log({ error });
      return dispatch(showAlert("Malumotlar noto'g'ri kiritilgan"));
    }
    //@ts-ignore
    putSignature(data).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post('/timestamp/pkcs7', {
          sign: pkcs7,
        })
        .then((sign: any) => {
          return reqGenerator.post(`/waybill/send/${key.TIN}`, {
            sign: sign.sign,
          });
        })
        .then(() => {
          history.push('/main/docs/sender');
          dispatch(showAlert('Hujjat muvoffaqiyatli yaratildi', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')))
        .catch(error => {
          console.log({ error });
        });
    });
  };

export const createActDoc =
  //@ts-ignore
  (values, productList) => async (dispatch, getState) => {
    const catalogList = getState().measures.catalogList;

    const guid = await reqGenerator.get('/utils/guid');
    const key = getState().auth.key;

    let data = null;

    try {
      data = JSON.stringify(
        computeActJSON(values, productList, guid, catalogList),
      );
    } catch (error) {
      console.log({ error });
      return dispatch(showAlert("Malumotlar noto'g'ri kiritilgan"));
    }
    //@ts-ignore
    putSignature(data).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post('/timestamp/pkcs7', {
          sign: pkcs7,
        })
        .then((sign: any) => {
          return reqGenerator.post(`/acts/${guid}/seller?tin=${key.TIN}`, {
            sign: sign.sign,
          });
        })
        .then(() => {
          history.push('/main/docs/sender');
          dispatch(showAlert('Hujjat muvoffaqiyatli yaratildi', 'success'));
        })
        .catch(error => {
          dispatch(showAlert(error.data.error.message, 'error'));
        })
        .finally(() => {
          dispatch(loaderOFF('fullPageLoader'));
        });
    });
  };
export const createPlaneDoc =
  //@ts-ignore
  values => async (dispatch, getState) => {
    const guid = await reqGenerator.get('/utils/guid');
    const key = getState().auth.key;
    // @ts-ignore
    let data = null;

    try {
      data = JSON.stringify(computePlaneDocJson(values, guid));
    } catch (error) {
      console.log({ error });
      return dispatch(showAlert("Malumotlar noto'g'ri kiritilgan"));
    }
    //@ts-ignore
    putSignature(data).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post('/timestamp/pkcs7', {
          sign: pkcs7,
        })
        .then((sign: any) => {
          return reqGenerator.post(`/document/send/${key.TIN}`, {
            sign: sign.sign,
          });
        })
        .then(res => {
          history.push('/main/docs/sender');
          dispatch(showAlert('Hujjat muvoffaqiyatli yaratildi', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')))
        .catch(error => {
          dispatch(showAlert(error.data.error.message, 'error'));
        });
    });
  };
export const saveActDoc =
  //@ts-ignore
  (values, productList, isEdit) => async (dispatch, getState) => {
    const catalogList = getState().measures.catalogList;

    const guid = await reqGenerator.get('/utils/guid');

    let data = null;

    try {
      data = computeActJSON(values, productList, guid, catalogList);
    } catch (error) {
      console.log({ error });
      return dispatch(showAlert("Malumotlar noto'g'ri kiritilgan"));
    }
    if (isEdit) {
      draftService.updateDraftData(data, 'act');
    } else {
      //@ts-ignore
      draftService.createDocDraft(data, 'act');
    }
    dispatch(showAlert('Вы сохранили этот документ', 'success'));
  };

export const createVerificationActDoc =
  //@ts-ignore
  (values, productList) => async (dispatch, getState) => {
    const guid = await reqGenerator.get('/utils/guid');
    const key = getState().auth.key;

    let data = null;

    try {
      data = JSON.stringify(
        computeVerificationActJSON(values, productList, guid),
      );
    } catch (error) {
      return dispatch(showAlert("Malumotlar noto'g'ri kiritilgan"));
    }
    //@ts-ignore
    putSignature(data).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post('/timestamp/pkcs7', {
          sign: pkcs7,
        })
        .then((sign: any) => {
          return reqGenerator.post(`/verification-act/send/${key.TIN}`, {
            sign: sign.sign,
          });
        })
        .then(() => {
          history.push('/main/docs/sender');
          dispatch(showAlert('Hujjat muvoffaqiyatli yaratildi', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')))
        .catch(error => {
          dispatch(showAlert(error.data.error.message, 'error'));
        });
    });
  };
export const saveVerificationActDoc =
  //@ts-ignore
  (values, productList) => async (dispatch, getState) => {
    const guid = await reqGenerator.get('/utils/guid');

    let data = null;

    try {
      data = JSON.stringify(
        computeVerificationActJSON(values, productList, guid),
      );
    } catch (error) {
      return dispatch(showAlert("Malumotlar noto'g'ri kiritilgan"));
    }
    //@ts-ignore
    draftService
      .createDocDraft(data, 'verification-act')
      .then(res =>
        dispatch(showAlert('Вы сохранили этот документ', 'success')),
      );
  };

export const createEmpowermentDoc =
  //@ts-ignore
  (values, productList) => async (dispatch, getState) => {
    const guid = await reqGenerator.get('/utils/guid');
    const key = getState().auth.key;
    const catalogList = getState().measures.catalogList;

    let data = null;

    try {
      data = JSON.stringify(
        computeEmpowermentJSON(values, productList, guid, catalogList),
      );
      console.log('data', JSON.stringify(data, null, 2));
    } catch (error) {
      return dispatch(showAlert("Malumotlar noto'g'ri kiritilgan"));
    }

    //@ts-ignore
    putSignature(data).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post('/timestamp/pkcs7', {
          sign: pkcs7,
        })
        .then((sign: any) => {
          return reqGenerator.post(
            `/empowerments/${guid}/buyer?tin=${key.TIN}`,
            {
              sign: sign.sign,
            },
          );
        })
        .then(() => {
          history.push('/main/docs/sender');
          dispatch(showAlert('Hujjat muvoffaqiyatli yaratildi', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')))
        .catch(error => {
          // handle error
          dispatch(showAlert(error.data.error.message, 'error'));
        });
    });
  };

export const createFacturaDoc =
  //@ts-ignore
  (values, productList, facturaId) => async (dispatch, getState) => {
    const guid = await reqGenerator.get('/utils/guid');
    const key = getState().auth.key;
    const userTin = getState().auth.userTin;
    const catalogList = getState().measures.catalogList;

    let data = null;

    try {
      data = JSON.stringify(
        computeFacturaJSON(values, productList, guid, catalogList),
      );
    } catch (error) {
      console.log({ error });
      return dispatch(showAlert("Malumotlar noto'g'ri kiritilgan"));
    }
    //@ts-ignore
    putSignature(data).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post(`/timestamp/pkcs7`, {
          sign: pkcs7,
        })
        .then((sign: any) => {
          return reqGenerator.post(`/facturas/${guid}/seller?tin=${userTin}`, {
            sign: sign.sign,
          });
        })
        .then(res => {
          history.push('/main/docs/sender');
          dispatch(showAlert('Hujjat muvoffaqiyatli yaratildi', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')))
        .catch(error => {
          // handle error
          dispatch(showAlert(error.data.error.message, 'error'));
          console.log({ error });
        });
    });
  };
export const saveFacturaDoc =
  //@ts-ignore
  (values, productList) => async (dispatch, getState) => {
    const guid = await reqGenerator.get('/utils/guid');
    const catalogList = getState().measures.catalogList;

    let data = null;
    try {
      data = computeFacturaJSON(values, productList, guid, catalogList);
    } catch (error) {
      console.log({ error });
      return dispatch(showAlert("Malumotlar noto'g'ri kiritilgan"));
    }
    //@ts-ignore
    draftService.createDocDraft(data, 'factura');
    dispatch(showAlert('Вы сохранили этот документ', 'success'));
  };
export const saveWaybillDoc =
  //@ts-ignore
  (values, productList, tin) => async (dispatch, getState) => {
    const guid = await reqGenerator.get('/utils/guid');
    const catalogList = getState().measures.catalogList;
    const key = getState().auth.key;

    let data = null;

    try {
      data = computeWaybill(values, productList, guid, tin, catalogList);
    } catch (error) {
      console.log({ error });
      return dispatch(showAlert("Malumotlar noto'g'ri kiritilgan"));
    }
    //@ts-ignore
    draftService.createDocDraft(data, 'waybill');
    dispatch(showAlert('Вы сохранили этот документ', 'success'));
  };
export const saveEmpowermentDoc =
  //@ts-ignore
  (values, productList) => async (dispatch, getState) => {
    const guid = await reqGenerator.get('/utils/guid');
    const catalogList = getState().measures.catalogList;
    let data = null;

    try {
      data = computeEmpowermentJSON(values, productList, guid, catalogList);
    } catch (error) {
      console.log({ error });
      return dispatch(showAlert("Malumotlar noto'g'ri kiritilgan"));
    }
    //@ts-ignore
    draftService.createDocDraft(data, 'empowerment');
    dispatch(showAlert('Вы сохранили этот документ', 'success'));
  };

export const createContractDoc =
  //@ts-ignore
  (values, productList) => async (dispatch, getState) => {
    const guid = await reqGenerator.get('/utils/guid');
    const key = getState().auth.key;
    const catalogList = getState().measures.catalogList;

    let data = null;

    try {
      data = JSON.stringify(
        computeContractJSON(values, productList, guid, catalogList),
      );
    } catch (error) {
      console.log({ error });
      return dispatch(showAlert("Malumotlar noto'g'ri kiritilgan"));
    }
    //@ts-ignore
    putSignature(data).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post('/timestamp/pkcs7', {
          sign: pkcs7,
        })
        .then((sign: any) => {
          return reqGenerator.post(`/contracts/${guid}/owner?tin=${key.TIN}`, {
            sign: sign.sign,
          });
        })
        .then(res => {
          history.push('/main/docs/sender');
          dispatch(showAlert('Hujjat muvoffaqiyatli yaratildi', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')))
        .catch(error => {
          // handle error
          dispatch(showAlert(error.data.error.message, 'error'));
        });
    });
  };

export const actRemoveDoc =
  //@ts-ignore
  (docData, docType, docId) => async (dispatch, getState) => {
    const key = getState().auth.key;

    const computedData = JSON.stringify(docData);

    console.log({ computedData });

    //@ts-ignore
    putSignature(computedData).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post('/timestamp/pkcs7', {
          sign: pkcs7,
        })
        .then((sign: any) => {
          return reqGenerator.put(`/acts/${docId}/seller?tin=${key.TIN}`, {
            sign: sign.sign,
            actId: docId,
          });
        })
        .then(res => {
          history.push('/main/docs/sender');
          dispatch(showAlert('Вы отменили этот документ', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')));
    });
  };
export const planeDocRemoveDoc =
  //@ts-ignore


  (docData = {}, docId) =>
    //@ts-ignore
    async (dispatch, getState) => {
      const key = getState().auth.key;

      const computedData = JSON.stringify(docData);
      //@ts-ignore
      putSignature(computedData).then(pkcs7 => {
        dispatch(loaderON('fullPageLoader'));
        reqGenerator
          .post(`/document/cancel/${key.TIN}`, {
            actId: docId,
          })
          .then(res => {
            history.push('/main/docs/sender');
            dispatch(showAlert('Вы отменили этот документ', 'success'));
          })
          .finally(() => dispatch(loaderOFF('fullPageLoader')));
      });
    };

export const verificationActRemoveDoc =
  //@ts-ignore
  (docData, docId) => async (dispatch, getState) => {
    const key = getState().auth.key;

    const computedData = JSON.stringify(docData);
    //@ts-ignore
    putSignature(computedData).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post(`/verification-act/cancel/${key.TIN}`, {
          sign: pkcs7,
          verificationActId: docId,
        })
        .then(res => {
          history.push('/main/docs/sender');
          dispatch(showAlert('Вы отменили этот документ', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')));
    });
  };
export const waybillRemoveDoc =
  //@ts-ignore
  (docData, docType, docId) => async (dispatch, getState) => {
    const key = getState().auth.key;

    const computedData = JSON.stringify(docData);
    //@ts-ignore
    putSignature(computedData).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post(`/waybill/cancel/${key.TIN}`, {
          sign: pkcs7,
          waybillId: docId,
        })
        .then(res => {
          history.push('/main/docs/sender');
          dispatch(showAlert('Вы отменили этот документ', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')));
    });
  };
//@ts-ignore
export const actAcceptDoc = (data, docId) => async (dispatch, getState) => {
  const key = getState().auth.key;

  putAttachedSignature(data).then(pkcs7 => {
    dispatch(loaderON('fullPageLoader'));
    reqGenerator
      .post('/timestamp/pkcs7', {
        sign: pkcs7,
      })
      .then((sign: any) => {
        return reqGenerator
          .post(`/acts/${docId}/buyer?tin=${key.TIN}`, {
            sign: sign.sign,
            action: 'accept',
            actId: docId,
            notes: '',
          })
          .then(res => {
            history.push('/main/docs/receiver');
            dispatch(showAlert('Вы приняли этот документ', 'success'));
          })
          .finally(() => dispatch(loaderOFF('fullPageLoader')));
      });
  });
};
export const planeDocAcceptDoc =
  //@ts-ignore


  (signHash = '', docId) =>
    //@ts-ignore
    async (dispatch, getState) => {
      const key = getState().auth.key;
      //@ts-ignore
      putSignature(signHash).then(pkcs7 => {
        dispatch(loaderON('fullPageLoader'));
        reqGenerator
          .post(`/document/accept-reject/${key.TIN}`, {
            action: 'accept',
            documentId: docId,
            notes: '',
          })
          .then(res => {
            history.push('/main/docs/receiver');
            dispatch(showAlert('Вы приняли этот документ', 'success'));
          })
          .finally(() => dispatch(loaderOFF('fullPageLoader')));
      });
    };
export const planeDocRejectDoc =
  //@ts-ignore
  (signHash, docId, notes) => async (dispatch, getState) => {
    const key = getState().auth.key;
    //@ts-ignore
    putSignature(signHash).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post(`/document/accept-reject/${key.TIN}`, {
          action: 'reject',
          documentId: docId,
          notes,
        })
        .then(res => {
          history.push('/main/docs/receiver');
          dispatch(showAlert('Вы отклонили этот документ', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')));
    });
  };
export const verificationActAcceptDoc =
  // @ts-ignore
  (data, docId) => async (dispatch, getState) => {
    const key = getState().auth.key;
    //@ts-ignore
    putAttachedSignature(data).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post('/timestamp/pkcs7', {
          sign: pkcs7,
        })
        .then((sign: any) => {
          return reqGenerator.post(
            `/verification-act/accept-reject/${key.TIN}`,
            {
              sign: sign.sign,
              action: 'accept',
              verificationActId: docId,
              notes: '',
            },
          );
        })
        .then(res => {
          history.push('/main/docs/receiver');
          dispatch(showAlert('Вы приняли этот документ', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')));
    });
  };
export const waybillAcceptDoc =
  // @ts-ignore
  (signHash, docId) => async (dispatch, getState) => {
    const key = getState().auth.key;
    //@ts-ignore
    putAttachedSignature(signHash).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post(`/waybill/accept-reject/${key.TIN}`, {
          sign: pkcs7,
          action: 'accept',
          waybillId: docId,
          notes: '',
        })
        .then(res => {
          history.push('/main/docs/receiver');
          dispatch(showAlert('Вы приняли этот документ', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')));
    });
  };

export const actRejectDoc =
  //@ts-ignore
  (docID, notes, sellerTin) => async (dispatch, getState) => {
    const key = getState().auth.key;

    const rejectFormatData = {
      act: null,
      notes,
    };

    try {
      const docData = await reqGenerator.get(
        `acts/${docID}/seller/decoded-sign?tin=${sellerTin}`,
      );
      //@ts-ignore

      rejectFormatData.act = docData;

      //@ts-ignore
      const pkcs7 = await putSignature(JSON.stringify(rejectFormatData));

      dispatch(loaderON('fullPageLoader'));

      const sign = await reqGenerator.post('/timestamp/pkcs7', { sign: pkcs7 });
      await reqGenerator.post(`/acts/${docID}/buyer?tin=${key.TIN}`, {
        //@ts-ignore
        sign: sign.sign,
        action: 'reject',
        actId: docID,
        notes,
      });

      history.push('/main/docs/receiver');
      dispatch(showAlert('Вы отклонили этот документ', 'success'));
    } catch (error) {
      //@ts-ignore
      dispatch(showAlert(error.data?.error?.message || 'Xatolik yuz berdi'));
    } finally {
      dispatch(loaderOFF('fullPageLoader'));
    }
  };
//@ts-ignore
//   putSignature(JSON.stringify(rejectFormatData)).then(pkcs7 => {
//     dispatch(loaderON('fullPageLoader'));
//     reqGenerator
//       .post('/timestamp/pkcs7', {
//         sign: pkcs7,
//       })
//       .then((sign: any) => {
//         reqGenerator
//           .post(`/act/accept-reject/${key.TIN}`, {
//             sign: sign.sign,
//             action: 'reject',
//             actId: docID,
//             notes,
//           })
//           .then(res => {
//             history.push('/main/docs/receiver');
//             dispatch(showAlert('Вы отклонили этот документ', 'success'));
//           })
//           .catch(error => {
//             dispatch(showAlert(error.data.error.message));
//           })
//           .finally(() => dispatch(loaderOFF('fullPageLoader')));
//       });
//   });
// };

export const verificationActRejectDoc =
  //@ts-ignore
  (docData, docID, notes) => async (dispatch, getState) => {
    const key = getState().auth.key;

    const rejectFormatData = {
      verificationAct: docData,
      notes,
    };
    //@ts-ignore
    putSignature(JSON.stringify(rejectFormatData)).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post(`/verification-act/accept-reject/${key.TIN}`, {
          sign: pkcs7,
          action: 'reject',
          verificationActId: docID,
          notes,
        })
        .then(res => {
          history.push('/main/docs/receiver');
          dispatch(showAlert('Вы отклонили этот документ', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')));
    });
  };
export const waybillRejectDoc =
  //@ts-ignore
  (docData, docID, notes) => async (dispatch, getState) => {
    const key = getState().auth.key;

    const rejectFormatData = {
      waybill: docData,
      notes,
    };
    //@ts-ignore
    putSignature(JSON.stringify(rejectFormatData)).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post(`/waybill/accept-reject/${key.TIN}`, {
          sign: pkcs7,
          action: 'reject',
          waybillId: docID,
          notes,
        })
        .then(res => {
          history.push('/main/docs/receiver');
          dispatch(showAlert('Вы отклонили этот документ', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')));
    });
  };

export const invoiceAcceptDoc =
  //@ts-ignore
  (signHash, docID) => async (dispatch, getState) => {
    const key = getState().auth.key;
    //@ts-ignore
    putAttachedSignature(signHash).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post('/timestamp/pkcs7', {
          sign: pkcs7,
        })
        .then((sign: any) => {
          return reqGenerator.post(`/facturas/${docID}/buyer?tin=${key.TIN}`, {
            sign: sign.sign,
            action: 'accept',
            facturaId: docID,
            notes: '',
          });
        })
        .then(res => {
          history.push('/main/docs/receiver');
          dispatch(showAlert('Вы приняли этот документ', 'success'));
        })
        .catch(error => dispatch(showAlert(error.data.error.message)))
        .finally(() => dispatch(loaderOFF('fullPageLoader')));
    });
  };

export const invoiceRejectDoc =
  //@ts-ignore
  (docID, notes, sellerTin) => async (dispatch, getState) => {
    const key = getState().auth.key;

    const rejectFormatData = {
      factura: null,
      notes,
    };

    try {
      const docData = await reqGenerator.get(
        `facturas/${docID}/seller/decoded-sign?tin=${sellerTin}`,
      );
      //@ts-ignore

      rejectFormatData.factura = docData;

      console.log('rejectFormatData', rejectFormatData);

      //@ts-ignore
      const pkcs7 = await putSignature(JSON.stringify(rejectFormatData));

      dispatch(loaderON('fullPageLoader'));

      const sign = await reqGenerator.post('/timestamp/pkcs7', { sign: pkcs7 });
      await reqGenerator.post(`/facturas/${docID}/buyer?tin=${key.TIN}`, {
        //@ts-ignore
        sign: sign.sign,
        action: 'reject',
        facturaId: docID,
        notes,
      });

      history.push('/main/docs/receiver');
      dispatch(showAlert('Вы отклонили этот документ', 'success'));
    } catch (error) {
      //@ts-ignore
      dispatch(showAlert(error.data?.error?.message || 'Xatolik yuz berdi'));
    } finally {
      dispatch(loaderOFF('fullPageLoader'));
    }
  };

export const invoiceRemoveDoc =
  //@ts-ignore
  (docData, docType, docId, userTin) => async (dispatch, getState) => {
    const key = getState().auth.key;

    const computedData = JSON.stringify(docData);
    //@ts-ignore
    putSignature(computedData).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post('/timestamp/pkcs7', {
          sign: pkcs7,
        })
        .then((sign: any) => {
          return reqGenerator.put(`/facturas/${docId}/seller?tin=${userTin}`, {
            sign: sign.sign,
            facturaId: docId,
            userTin,
          });
        })
        .then(() => {
          history.push('/main/docs/sender');
          dispatch(showAlert('Вы отменили этот документ', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')));
    });
  };

export const empowermentRemoveDoc =
  //@ts-ignore
  (docData, docType, docId) => async (dispatch, getState) => {
    const key = getState().auth.key;

    const computedData = JSON.stringify(docData);
    //@ts-ignore
    putSignature(computedData).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post('/timestamp/pkcs7', {
          sign: pkcs7,
        })
        .then((sign: any) => {
          return reqGenerator.put(
            `/empowerments/${docId}/buyer?tin=${key.TIN}`,
            {
              sign: sign.sign,
              empowermentId: docId,
            },
          );
        })
        .then(res => {
          history.push('/main/docs/sender');
          dispatch(showAlert('Вы отменили этот документ', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')));
    });
  };

export const empowermentAcceptDoc =
  //@ts-ignore
  (signHash, docId, docData, who, agentEmpowermentId) => async (dispatch, getState) => {
    try {
      dispatch(loaderON('fullPageLoader'));

      const key = getState().auth.key;
      const catalogList = getState().measures.catalogList;

      // Format the document using computeEmpowermentJSON
      const formattedData = JSON.stringify(
        computeEmpowermentJSON(docData, docData.products?.productList || [], docId, catalogList)
      );

      // Sign the formatted document
      const mySign = await putSignature(formattedData, key);

      // Join signatures - use URLEncoded data format instead of JSON
      // This matches the expected format in e-imzo-doc examples
      const pkcsData = `${mySign}|${signHash}`;
      try {
        // Make a direct POST request with application/x-www-form-urlencoded content type
        const response = await directRequest.post('/frontend/pkcs7/join', pkcsData);

        // Check if response has the required pkcs7b64 data
        if (!response || typeof response !== 'object' || !('pkcs7b64' in response)) {
          throw new Error('Invalid response from pkcs7/join: Missing pkcs7b64 data');
        }

        // Use reqGenerator for the endpoints that still need api/ prefix
        await reqGenerator.post(
          `empowerments/${docId}/agent?tin=${key.PINFL}`,
          {
            sign: response.pkcs7b64,
            action: 'accept',
            empowermentId: docId,
            agentEmpowermentId,
            notes: '',
          }
        );

        history.push('/main/docs/receiver');
        dispatch(showAlert('Вы приняли этот документ', 'success'));
      } catch (innerError: any) {
        console.error("Error calling pkcs7/join:", innerError);
        throw new Error(`PKCS7 join error: ${innerError?.message || 'Unknown error'}`);
      }
    } catch (error: any) {
      console.error('Error accepting empowerment document:', error);
      dispatch(showAlert('Ошибка при принятии документа: ' + (error?.message || error), 'error'));
    } finally {
      dispatch(loaderOFF('fullPageLoader'));
    }
  }

//@ts-ignore
//   putAttachedSignature(signHash).then(pkcs7 => {
//     console.log('signHash', signHash);

//     dispatch(loaderON('fullPageLoader'));
//     reqGenerator
//       .post('/timestamp/pkcs7', {
//         sign: pkcs7,
//       })
//       .then((sign: any) => {
//         return reqGenerator.post(
//           `empowerments/${docId}/agent?tin=${key.PINFL}`,
//           {
//             sign: sign.sign,
//             action: 'accept',
//             empowermentId: docId,
//             agentEmpowermentId,
//             notes: '',
//           },
//         );
//       })
//       .then(res => {
//         history.push('/main/docs/receiver');
//         dispatch(showAlert('Вы приняли этот документ', 'success'));
//       })
//       .finally(() => dispatch(loaderOFF('fullPageLoader')));
//   });
// };

export const empowermentBuyerAcceptDoc =
  //@ts-ignore
  (signHash, docId, who, agentEmpowermentId) => async (dispatch, getState) => {
    const key = getState().auth.key;
    //@ts-ignore
    putAttachedSignature(signHash).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post('/timestamp/pkcs7', {
          sign: pkcs7,
        })
        .then((sign: any) => {
          return reqGenerator.post(
            `empowerments/${docId}/seller?tin=${key.TIN}`,
            {
              sign: sign.sign,
              action: 'accept',
              empowermentId: docId,
              agentEmpowermentId,
              notes: '',
            },
          );
        })
        .then(res => {
          history.push('/main/docs/receiver');
          dispatch(showAlert('Вы приняли этот документ', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')));
    });
  };

export const empowermentRejectDoc =
  //@ts-ignore


  (docData, docId, notes, who, agentEmpowermentId) =>
    //@ts-ignore
    async (dispatch, getState) => {
      const key = getState().auth.key;
      const rejectFormatData = {
        empowerment: docData,
        notes,
      };

      //@ts-ignore
      putSignature(JSON.stringify(rejectFormatData)).then(pkcs7 => {
        dispatch(loaderON('fullPageLoader'));
        reqGenerator
          .post('/timestamp/pkcs7', {
            sign: pkcs7,
          })
          .then((sign: any) => {
            return reqGenerator.post(
              `empowerments/${docId}/agent?tin=${key.TIN}`,
              {
                sign: sign.sign,
                action: 'reject',
                empowermentId: docId,
                agentEmpowermentId,
                notes,
              },
            );
          })
          .then(res => {
            history.push('/main/docs/receiver');
            dispatch(showAlert('Вы отклонили этот документ', 'success'));
          })
          .finally(() => dispatch(loaderOFF('fullPageLoader')));
      });
    };

export const empowermentBuyerRejectDoc =
  //@ts-ignore


  (docData, docId, notes, who, agentEmpowermentId) =>
    //@ts-ignore
    async (dispatch, getState) => {
      const key = getState().auth.key;
      const rejectFormatData = {
        empowerment: docData,
        notes,
      };

      //@ts-ignore
      putSignature(JSON.stringify(rejectFormatData)).then(pkcs7 => {
        dispatch(loaderON('fullPageLoader'));
        reqGenerator
          .post('/timestamp/pkcs7', {
            sign: pkcs7,
          })
          .then((sign: any) => {
            return reqGenerator.post(
              `empowerments/${docId}/seller?tin=${key.TIN}`,
              {
                sign: sign.sign,
                action: 'reject',
                empowermentId: docId,
                agentEmpowermentId,
                notes,
              },
            );
          })
          .then(res => {
            history.push('/main/docs/receiver');
            dispatch(showAlert('Вы отклонили этот документ', 'success'));
          })
          .finally(() => dispatch(loaderOFF('fullPageLoader')));
      });
    };

export const contractRemoveDoc =
  //@ts-ignore
  (docData, docType, docId) => async (dispatch, getState) => {
    const key = getState().auth.key;

    const computedData = JSON.stringify(docData);
    //@ts-ignore
    putSignature(computedData).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post('/timestamp/pkcs7', {
          sign: pkcs7,
        })
        .then((sign: any) => {
          reqGenerator.put(`/contracts/${docId}/owner?tin=${key.TIN}`, {
            sign: sign.sign,
            contractId: docId,
          });
        })
        .then(res => {
          history.push('/main/docs/sender');
          dispatch(showAlert('Вы отменили этот документ', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')));
    });
  };

export const contractRejectDoc =
  //@ts-ignore
  (docData, docId, notes) => async (dispatch, getState) => {
    const key = getState().auth.key;

    const rejectFormatData = {
      contract: docData,
      notes,
    };
    //@ts-ignore
    putSignature(JSON.stringify(rejectFormatData)).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post('/timestamp/pkcs7', {
          sign: pkcs7,
        })
        .then((sign: any) => {
          return reqGenerator.post(
            `/contracts/${docId}/client?tin=${key.TIN}`,
            {
              sign: sign.sign,
              action: 'reject',
              contractId: docId,
              notes,
            },
          );
        })
        .then(res => {
          history.push('/main/docs/receiver');
          dispatch(showAlert('Вы отклонили этот документ', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')));
    });
  };

export const contractAcceptDoc =
  //@ts-ignore
  (signHash, docId) => async (dispatch, getState) => {
    const key = getState().auth.key;
    //@ts-ignore
    putAttachedSignature(signHash).then(pkcs7 => {
      dispatch(loaderON('fullPageLoader'));
      reqGenerator
        .post('/timestamp/pkcs7', {
          sign: pkcs7,
        })
        .then((sign: any) => {
          return reqGenerator.post(
            `/contracts/${docId}/client?tin=${key.TIN}`,
            {
              sign: sign.sign,
              action: 'accept',
              contractId: docId,
              notes: '',
            },
          );
        })
        .then(res => {
          history.push('/main/docs/receiver');
          dispatch(showAlert('Вы приняли этот документ', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')));
    });
  };

export const saveContractDoc =
  //@ts-ignore
  (values, productList) => async (dispatch, getState) => {
    const guid = await reqGenerator.get('/utils/guid');
    const catalogList = getState().measures.catalogList;

    let data = null;

    try {
      data = computeContractJSON(values, productList, guid, catalogList);
    } catch (error) {
      return dispatch(showAlert("Malumotlar noto'g'ri kiritilgan"));
    }

    draftService.createDocDraft(data, 'contract');
    dispatch(showAlert('Вы сохранили этот документ', 'success'));
  };

export const saveDocToDraft =
  //@ts-ignore
  (docData, productList, docType, isEdit, id) => async (dispatch, getState) => {
    if (isEdit) {
      const computedData = {
        Id: id,
        ...docData,
        products: { productList: productList },
      };

      dispatch(loaderON('fullPageLoader'));
      draftService
        .updateDraftData(computedData, docType)
        .then(res => {
          history.push('/main/drafts');
          dispatch(showAlert('Вы сохранили этот документ', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')));
    } else {
      const computedData = {
        ...docData,
        products: { productList: productList },
      };

      dispatch(loaderON('fullPageLoader'));
      draftService
        .createDraft(computedData, docType)
        .then(res => {
          history.push('/main/drafts');
          dispatch(showAlert('Вы сохранили этот документ', 'success'));
        })
        .finally(() => dispatch(loaderOFF('fullPageLoader')));
    }
  };
